<template>
  <div :class="{ full: props.full }" class="image-details">
    <div class="image">
      <CustomImage :image="props.image" :sizes="[330, 330]" />
    </div>

    <p class="title">{{ props.detail1 }}</p>
    <p class="subtitle">{{ props.detail2 }}</p>
  </div>
</template>

<script setup>
const props = defineProps({
  image: Object,
  detail1: String,
  detail2: String,
  full: Boolean,
});
</script>

<style
  src="@/assets/css/components/homepage/image-details.scss"
  lang="scss"
  scoped
></style>
